<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Reporte de Movimiento</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" @submit.prevent="Validate">
            <b-row class="justify-content-center">
                <b-col md="6">
                  <b-form-group>
                    <label>Socio: </label>
                    <v-select placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchPartner" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <!-- <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col> -->

                
            </b-row>
          </b-form>


          <b-row>
            <b-col md="4">
               <b-card>
                <div class="w-100 text-center mb-1">
                  <strong class="text-center">INFORMACIÓN DE SOCIO</strong> 
                </div>
                <div class="w-100">
                  <strong>N° Documento:</strong> {{ mpartner.document_number }} <br>
                  <strong>Nombres:</strong> {{ mpartner.name }} <br>
                  <strong>Telefono:</strong> {{ mpartner.phone }} - {{ mpartner.cell_phone }} <br>
                  <strong>Dirección:</strong> {{ mpartner.address }} <br>
                  <strong>Estado:</strong> {{ mpartner.state }} <br>
                  <strong>Fecha de Registro:</strong> {{ mpartner.registration_date }} <br>
                </div>
                
              </b-card>
            </b-col>
            <b-col md="4">
              <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center" colspan="19">HISTORIAL DE MOVIMIENTOS</th>
                    </tr>
                    <tr>
                      <th width="30%" class="text-center">Fecha</th>
                      <th width="40%" class="text-center">Usuario</th>
                      <th width="30%" class="text-center">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col md="4">
              <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center" colspan="19">HISTORIAL DE GARANTES</th>
                    </tr>
                    <tr>
                      <th width="20%" class="text-center">Prestamo</th>
                      <th width="65%" class="text-center">Socio</th>
                      <th width="15%" class="text-center">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, it) in guarantors" :key="it">
                      <td class="text-center"> {{ item.number  }}</td>
                      <td class="text-center"> {{ item.name + " "+ item.document_number }}</td>
                      <td class="text-center">
                           <b-badge v-if="item.state == 0" variant="danger">Cancelado</b-badge>
                          <b-badge v-if="item.state == 1" variant="warning">Pendiente</b-badge>
                          <b-badge v-if="item.state == 2" variant="success">Realizado</b-badge>
                          <b-badge v-if="item.state == 3" variant="primary">Finalizado</b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>

          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
             <thead>
                  <tr>
                    <th class="text-center" colspan="19">HISTORIAL DE PRESTAMOS </th>
                  </tr>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="6%" class="text-center">Codigo</th>
                    <th width="6%" class="text-center">Desem.</th>
                    <th width="5%" class="text-center">Aporte</th>
                    <th width="5%" class="text-center">Sepelio</th>
                    <th width="7%" class="text-center">F. Desem.</th>
                    <th width="7%" class="text-center">F. Pago</th>
                    <th width="6%" class="text-center">Monto</th>
                    <th width="3%" class="text-center">C.</th>
                    <th width="4%" class="text-center">Int.</th>
                    <th width="6%" class="text-center">C. Fija</th>
                    <th width="6%" class="text-center">S. Cap.</th>
                    <th width="6%" class="text-center">U. Fecha</th>
                    <th width="5%" class="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(item, it) in loans" :key="it">
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.number  }}</td>
                    <td class="text-left"> {{ item.check  }}</td>
                    <td class="text-right"> 10.00</td>
                    <td class="text-right"> 5.00</td>
                    <td class="text-center"> {{ item.disbursement_date  }}</td>
                    <td class="text-center"> {{ item.payment_date  }}</td>
                    <td class="text-right"> {{ item.amount  }}</td>
                    <td class="text-center"> {{ item.dues  }}</td>
                    <td class="text-right"> {{ item.interest_total  }}</td>
                    <td class="text-right"> {{ item.final_installment  }}</td>
                    <td class="text-right"> {{ item.principal_balance  }}</td>
                    <td class="text-center"> {{  item.last_payment }}</td>
                    <td class="text-center">
                        <b-badge v-if="item.state == 0" variant="danger">Cancelado</b-badge>
                        <b-badge v-if="item.state == 1" variant="warning">Pendiente</b-badge>
                        <b-badge v-if="item.state == 2" variant="success">Realizado</b-badge>
                        <b-badge v-if="item.state == 3" variant="primary">Finalizado</b-badge>
                    </td>
                  </tr>
                </tbody>
              
            </table>
          </div>

          <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead>
                  <tr>
                    <th class="text-center" colspan="19">HISTORIAL DE APORTES </th>
                  </tr>
                 <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="30%" class="text-center">Año</th>
                    <th width="35%" class="text-center">Meses</th>
                    <th width="10%" class="text-center">Aporte</th>
                    <th width="10%" class="text-center">Sepelio</th>
                    <th width="10%" class="text-center">Total</th>
              
                  </tr>
                </thead>
                <tbody v-for="(item, it) in contributions" :key="it">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-left">{{ item.year }}</td>
                      <td class="align-middle text-left">{{ item.month }}</td>
                      <td class="align-middle text-right">{{ item.contribution }}</td>
                      <td class="align-middle text-right">{{ item.burial }}</td>
                      <td class="align-middle text-right">{{ item.total }}</td>
                  </tr>
                </tbody>
              </table>
          </div>

          <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead>
                  <tr>
                    <th class="text-center" colspan="19">HISTORIAL DE LIQUIDACIONES </th>
                  </tr>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">N° Liquidación</th>
                    <th width="15%" class="text-center">Desembolso</th>
                    <th width="15%" class="text-center">F. Solicitud</th>
                    <th width="15%" class="text-center">F. Desembolso</th>
                    <th width="10%" class="text-center">Total</th>
                    <th width="10%" class="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in settlement_contribution" :key="it">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-left">{{ item.number }}</td>
                      <td class="align-middle text-left">{{ item.check }}</td>
                      <td class="align-middle text-center">{{ item.application_date }}</td>
                      <td class="align-middle text-center">{{ item.disbursement_date }}</td>
                      <td class="align-middle text-right">{{ item.total }}</td>
                      <td class="align-middle text-center">
                        <b-badge v-if="item.state == 2" variant="success">Aceptado</b-badge>
                        <b-badge v-if="item.state == 1" variant="warning">Pendiente</b-badge>
                        <b-badge v-if="item.state == 0" variant="danger">Rechazado</b-badge>
                      </td>
                        
                  </tr>
                </tbody>
              </table>
          </div>

          <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead>
                  <tr>
                    <th class="text-center" colspan="19">HISTORIAL DE SEPELIOS</th>
                  </tr>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">N° Sepelio</th>
                    <th width="10%" class="text-center">Parentesco</th>
                    <th width="30%" class="text-center">Familiar y/o Beneficiario</th>
                    <th width="10%" class="text-center">Desembolso</th>
                    <th width="10%" class="text-center">F. Solicitud</th>
                    <th width="10%" class="text-center">F. Desembolso</th>
                    <th width="10%" class="text-center">Total</th>
                    <th width="10%" class="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in burials" :key="it">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-left">{{ item.number }}</td>
                      <td class="align-middle text-left">{{ NameTypeBeneficiary(item.type_beneficiary) }}</td>
                      <td class="align-middle text-left">{{ item.name + " - " + item.document_number}}</td>
                      <td class="align-middle text-left">{{ item.check }}</td>
                      <td class="align-middle text-center">{{ item.application_date }}</td>
                      <td class="align-middle text-center">{{ item.disbursement_date }}</td>
                      <td class="align-middle text-right">{{ item.total }}</td>
                      <td class="align-middle text-center">
                        <b-badge v-if="item.state == 2" variant="success">Aceptado</b-badge>
                        <b-badge v-if="item.state == 1" variant="warning">Pendiente</b-badge>
                        <b-badge v-if="item.state == 0" variant="danger">Rechazado</b-badge>
                      </td>
                        
                  </tr>
                </tbody>
              </table>
          </div>
          

  
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportMovement',
      role:1,
      perPage: 15,
      currentPage: 1,
      rows: 0,
      mpartner: {
        document_number:'',
        name:'',
        registration_date:'',
      },
      guarantors: [],
      loans: [],
      contributions: [],
      settlement_contribution: [],
      burials: [],
      report:{
        id_partner:'',
      },
      partners : [],
      partner: null,

      errors:{
        id_partner:false,
      }
    };
  },
  mounted() {
   
  },
  methods: {
    SearchPartner,
    Validate,
    Report,
    ExportExcel,
    NameTypeBeneficiary,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function SearchPartner(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}

function NameTypeBeneficiary(code) {
  let name = "";
  switch (code) {
    case "1": name = "Titular Directo"; break;
    case "2": name = "Familiar"; break;
  }
  return name;
}

function ExportExcel() {  
  this.errors.id_partner = this.partner == null ? true : false;
  if (this.errors.id_partner == true) { return false; }
  let id_partner = this.partner.id;
  let me = this;
  let url = me.url_base + "report-movement-excel/"+id_partner;
  window.open(url,'_blank');
}

function Validate() {
  
  this.errors.id_partner = this.partner == null ? true : false;
  if (this.errors.id_partner == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  this.Report();
}


function Report() {
  let me = this;
  me.report.id_partner = me.partner.id;
  let data = me.report;
  let url = this.url_base + "report/movement";
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mpartner = response.data.result.partner;
        me.guarantors = response.data.result.guarantors;
        me.loans = response.data.result.loans;
        me.contributions = response.data.result.contributions;
        me.settlement_contribution = response.data.result.settlement_contribution;
        me.burials = response.data.result.burials;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
     .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}





</script>
